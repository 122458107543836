import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../assets/logo/myedulife.png";

export default function Footer() {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        bottom: "0px",
        left: "0px",
        right: "0px",
        marginBottom: "0px",
      }}
    >
      <img
        style={{ width: "48px", height: "48px" }}
        alt="logo"
        src={logo}
      ></img>
      <Typography fontSize={12} padding={5}>
        © 2022 MyEduLife. A joint-project of higher educational institutions
      </Typography>
    </Box>
  );
}
