import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

interface Gradient {
  gradient1: string;
}

interface Height {
  header: string;
  mobileHeader: string;
  footer: string;
}
interface Width {
  sidebar: string;
  maxContent: string;
}

interface TextColor {
  red: string;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  primary: string;
}

interface BgColor {
  bg1: string;
  bg2: string;
  bg3: string;
  bg4: string;
  bg5: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
    gradient: Gradient;
    height: Height;
    width: Width;
  }
}

declare module "@mui/material/styles/createTheme" {
  interface ThemeOptions {
    textColor: TextColor;
    bgColor: BgColor;
    gradient: Gradient;
    height: Height;
    width: Width;
  }
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
    gradient: Gradient;
    height: Height;
    width: Width;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: `'Inter', sans-serif`,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#323a3c",
    },
    secondary: {
      main: "rgb(120 186 65)",
    },
    background: {
      default: "#ffdf82",
    },
    info: {
      main: "#ffdf82",
    },
    success: {
      main: "#93c99e",
    },
    divider: "rgba(0,0,0,0.1)",
  },
  textColor: {
    red: "#EE4B2B",
    text1: "#252525",
    text2: "#333333",
    text3: "#727272",
    text4: "#999999",
    text5: "#CCCCCC",
    primary: "#31B047",
  },
  bgColor: {
    bg1: "#000000",
    bg2: "#191919",
    bg3: "#252525",
    bg4: "#303030",
    bg5: "#A1A1A1",
  },
  gradient: {
    gradient1:
      "#ffffff linear-gradient(154.62deg, #77C803 9.44%, #28A03E 59.25%);",
  },
  height: {
    header: "80px",
    mobileHeader: "51px",
    footer: "150px",
  },
  width: {
    sidebar: "250px",
    maxContent: "1200px",
  },
  shape: {
    borderRadius: 10,
  },
  spacing: (factor: number) => `${1 * factor}px`,
  // gray: {
  //   main: '#333333',
  //   dark: '#262626',
  // },
});

export function ThemeProvider({ children, theme }: any) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
