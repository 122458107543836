import { Box, CircularProgress, Typography } from "@mui/material";

export default function Loader() {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "center",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <CircularProgress
        color="secondary"
        sx={{ padding: "10px", alignSelf: "center" }}
      />
      <br />
      {/* <Typography sx={{ fontSize: 10, fontWeight: 400 }}>
        Loading
      </Typography> */}
    </Box>
  );
}
