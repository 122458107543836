import Keycloak from "keycloak-js";



const keycloak = new Keycloak({
    url: "https://kc.myedulife.dbis.rwth-aachen.de",
    realm: "control-center",
    clientId: "angular-js-app-client",
});

export default keycloak;