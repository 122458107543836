import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./pages/App";
import { Web3Provider } from "@ethersproject/providers";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "./constants/chain";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Loader from "./components/Loader";
import keycloak from "./Keycloak";

function getLibrary(provider: any) {
  return new Web3Provider(provider, "any");
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    LoadingComponent={<Loader />}
    initOptions={{ onLoad: "login-required" }}
  >
    <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);
