export const routes = {
  login: "/login",
  home: "/home",
  account: "/account",
  requests: "/requests",
  history: "/history",
  overview: "/overview/:id",
  upload: "/upload",
  createRequest: "/create",
  manage: "/manage/:id",
};
