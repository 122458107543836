import { Box, Card, Typography } from "@mui/material";

export default function Login() {
  return (
    <Box display="flex" sx={{ justifyContent: "center", padding: "20px" }}>
      <Card sx={{ padding: 10 }}>
        <Typography color={"red"}>Please login to access the MyEduLife issuer control center</Typography>
      </Card>
    </Box>
  );
}
