import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo/myedulife.png";
import { routes } from "../../constants/routes";
import "../../main.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
//import {OpenIDConnectSignInReact} from "openidconnect-signin/openidconnect-signin-react.js";

//import "openidconnect-signin/openidconnect-signin-react";
import getUserInfo from "../../hooks/getUserInfo";
import { useKeycloak } from "@react-keycloak/web";

export default function Header() {
  const { keycloak, initialized } = useKeycloak();

  const navigate = useNavigate();

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState(getUserInfo());

  /*   useEffect(() => {
    if (user !== null) {
      setIsSignedIn(true);
    }
  }, [user]); */

  window.addEventListener("signed-in", () => {
    setIsSignedIn(true);
    setUser(getUserInfo());
  });

  window.addEventListener("signed-out", () => {
    setIsSignedIn(false);
    setUser(null);
  });

  window.addEventListener("signed-out", window.location.reload);

  /* const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (!clicked) {
      dispatchEvent(new CustomEvent("user-change", { bubbles: true }));
    } else {
      // perform action for subsequent clicks
      dispatchEvent(new CustomEvent("user-change", { bubbles: true }));
    }
    setClicked(true);
  };
 */
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar color="primary" position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            display="flex"
            sx={{ flexDirection: "row", alignItems: "center" }}
          >
            <img
              style={{ width: "48px", height: "48px" }}
              alt="logo"
              src={logo}
            ></img>
            <Typography
              component="div"
              variant="h6"
              sx={{
                py: 2,
                cursor: "pointer",
                fontFamily: `"Montserrat", cursive`,
              }}
              onClick={() => navigate(routes.home)}
            >
              MyEduLife issuer control center
            </Typography>
          </Box>

          <div />
          <Button
            variant="contained"
            color="secondary"
            style={{ textTransform: "none" }}
            onClick={() => {
              dispatchEvent(new CustomEvent("user-change", { bubbles: true }));
            }}
          >
            Change Role
          </Button>
          <Box display="flex" sx={{ flexDirection: "row" }}>
            {!keycloak.authenticated ? (
              <Button
                variant="contained"
                color="secondary"
                style={{ textTransform: "none", marginRight: "10px" }}
                onClick={() => {
                  keycloak.login();
                }}
              >
                Login with Keycloak
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                style={{ textTransform: "none" }}
                onClick={() => keycloak.logout()}
              >
                Logout
              </Button>
            )}

            {keycloak ? (
              <Box display="flex" sx={{ alignItems: "center", marginLeft: 10 }}>
                <AccountBoxIcon
                  onClick={() => {
                    navigate(routes.account);
                  }}
                />

                <Box
                  display="flex"
                  sx={{
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    {keycloak.tokenParsed && keycloak.tokenParsed.name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 10, fontWeight: 400 }}
                    onClick={() => {
                      navigate(routes.account);
                    }}
                  >
                    at RWTH Aachen
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box></Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
