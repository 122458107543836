import { ThemeProvider } from "@mui/material";
import { Suspense, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { routes } from "../constants/routes";
import { theme } from "../theme";
//import Account from "./Account";
//import Home from "./Home";
//import Overview from "./Overview";
//import Requests from "./Requests";
import PrivateRoute from "./PrivateRoute";
import useLoggedIn from "../hooks/useLoggedIn";
import React from "react";
import Loader from "../components/Loader";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../Keycloak";

const Account = React.lazy(() => import("./Account"));
const Overview = React.lazy(() => import("./Overview"));
const Requests = React.lazy(() => import("./Requests"));
const History = React.lazy(() => import("./Requests/history"));
const Home = React.lazy(() => import("./Home"));
const UserHome = React.lazy(() => import("./Home/userHome"));
const Upload = React.lazy(() => import("./Upload"));
const Create = React.lazy(() => import("./Requests/create"));
const Manage = React.lazy(() => import("./Overview/manage"));

function App() {
  const [user, setUser] = useState(useLoggedIn());
  const navigate = useNavigate();
  const [isHolder, setIsHolder] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleSignIn = () => {
    setUser("hi");
    navigate("/home");
  };

  const handleUser = () => {
    if (!clicked) {
      setIsHolder(true);
      setClicked(true);
    } else {
      setIsHolder(false);
      setClicked(false);
    }
  };
  // window.addEventListener("signed-in", handleSignIn);
  window.addEventListener("user-change", handleUser);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to={routes.home} replace />} />
          <Route
            path={routes.home}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  {isHolder ? <UserHome /> : <Home />}
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.account}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Account />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.overview}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Overview />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.requests}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Requests />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.history}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <History />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.upload}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Upload />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.createRequest}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Create />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.manage}
            element={
              <PrivateRoute>
                <Suspense fallback={<Loader />}>
                  <Manage />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Routes>
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default App;
